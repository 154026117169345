<template>
  <div :style="{ height: tableMaxHeight + 'px' }" class="canvasWrapper" @mousedown="mouseDownEventForCanvas"
    @mouseup="mouseUpEventForCanvas" @mouseover="mouseUpEventForCanvas" @mouseleave="mouseUpEventForCanvas"
    @mouseout="mouseUpEventForCanvas" @mousemove="mouseMoveEventForCanvas" @contextmenu="handleContextMenuForCanvas">
    <div class="zoomInOutPanel">
      <el-button size="small" @click="handleZoomIn" circle>
        <el-icon>
          <plus />
        </el-icon>
      </el-button>
      <br />
      <el-button size="small" style="margin-top: 10px" @click="handleZoomOut" circle>
        <el-icon>
          <minus />
        </el-icon>
      </el-button>
    </div>
    <div class="branchVSColor">
      <div v-for="item in options.lessonTypeList" :key="item">
        <div class="item" :style="`background:${this.config.mainBranchSetting['T' + item.value].bgColor}`">
          {{ this.config.mainBranchSetting["T" + item.value].title }}
        </div>
      </div>
    </div>
    <div class="leafVSColor">
      <div v-for="item in options.scopeList" :key="item">
        <div class="item" :style="`background:${item.leaf_BG_Color}`">
          {{ item.title }}
        </div>
      </div>
    </div>
    <div class="handle-box">
      <el-select v-model="gotoGrade" placeholder="Grade" class="gradeAndTerm" @change="handleSelectGrade" size="small">
        <el-option v-for="item in options.gradeList" :key="item.name"
          :label="this.$formatter.formatGrade(item.description)" :value="item.name"></el-option>
      </el-select>
      <el-select v-model="gotoTerm" placeholder="Term" class="gradeAndTerm" @change="handleSelectTerm" size="small">
        <el-option v-for="item in options.termList" :key="item.name" :label="item.description"
          :value="item.name"></el-option>
      </el-select>
      <el-button size="small" style="padding: 7px" @click="jumpToGradeAndTermPad('smooth')" circle>
        <el-icon color="orange">
          <CaretRight />
        </el-icon>
      </el-button>
    </div>
    <div class="canvasPanel" id="canvasPanel" @scroll="handleScrollCanvasPanel">
      <canvas id="jeTreeCanvas" @mousemove="handleMouseMoveOnCanvas"></canvas>"
    </div>
    <el-button ref="previousButton" style="display: none; position: absolute; z-index: 100" :size="small" circle
      @click="jumpToSameKnowledgePointLesson('previous')">
      <el-icon>
        <top />
      </el-icon>
    </el-button>
    <el-button ref="nextButton" :size="small" circle style="display: none; position: absolute; z-index: 100"
      @click="jumpToSameKnowledgePointLesson('next')">
      <el-icon>
        <bottom />
      </el-icon>
    </el-button>
  </div>
  <el-dialog :title="testRecordReportDialogTitle" v-model="showTestRecordReportDialog" width="90%">
    <test-report-chart :isForAdmin="false" :reportId="currentReport.reportId" :studentId="query.studentId"
      :destroy-on-close="true" />
  </el-dialog>
</template>

<script>
import { getData, postData } from "../../service/api";
import { h } from "vue";
import { ElMessageBox } from "element-plus";
import { LessonOperationEnum, LessonCategoryEnum } from "../../service/models/enums.js";
import TestReportChart from "./TestReportChart.vue";

export default {
  components: { TestReportChart },
  props: {
    isForAdmin: Boolean,
  },
  data() {
    return {
      controllerUrl: "/lesson",
      query: { studentId: "" },
      options: {
        gradeList: [],
        termList: [],
        scopeList: [],
        lessonTypeList: [],
      },
      config: {
        scopeSetting: {
          Number_And_Algebra: {
            bgColor: "rgba(255, 0, 0, 0.2)",
            bgColorForHover: "rgba(255, 0, 0, 0.5)",
            foreColor: "rgba(0, 0, 0, 1)",
            foreColorForHover: "rgba(0, 0, 0, 1)",
            font: "1em Roboto, Arial",
            padding: 10,
            lineHeight: 18,
          },
        },
        gradeTermPadSetting: {
          bgColor: "rgba(0, 255, 255, 0.2)",
          foreColor: "rgba(0, 0, 0, 1)",
          font: "bold 1.1em Roboto, Arial",
          bottomPadding: 20,
          height: 48,
        },
        groundSetting: {
          angle: 45,
          paddingTop: 40,
          bgColor: "rgba(0, 255, 0, 0.2)",
          font: "1.1em Roboto, Arial",
        },
        mainBranchSetting: {
          T1: {
            name: "",
            lessonType: 0,
            bgColor: "rgba(50, 50, 50, 0.15)",
            width: 0,
            startOfX: 0,
            startOfY: 0,
            lineCap: "square",
            lineDash: [],
          },
        },
        leafBranchSetting: {
          bgColor: "rgba(0, 0, 0, 0.2)",
          paddingTopToLeafBottom: 30,
          width: 2,
        },
        tooltipSetting: {
          element: null,
          lessonData: null,
          bgColor: "rgba(0, 0, 0, 0.6)",
          foreColor: "rgba(255, 255, 255, 1)",
          font: "14px/18px Roboto, Arial",
          padding: 10,
          width: 280,
          radius: 5,
        },
        scale: 1,
        radius: 5,
        leafShadowColor: "rgba(0, 0, 0, 1)",
        leafShadowBlurSize: 20,
        leafWidth: 180,
        leafMinHeight: 68,
        startOfX: 50,
        startOfY: 10,
        startDragOffsetX: 0,
        startDragOffsetY: 0,
        currentOfX: 0,
        currentOfY: 0,
        leafTextAlign: "center",
        leftPaddingToBranch: 80,
        rightPaddingToBranch: 80,
        branchWidth: 16,
        branchPadding: 30,
        bottomPadding: 10,
        curvedLineHeight: 180,
        lineCap: "square",
        lineDash: [],
        lineDashForEmptyBranch: [16, 32],
      },
      leafDialogContentId: "leaf_dialog",
      tableMaxHeight: 1100,
      studentInClassRooms: [],
      courseList: [],
      gotoGrade: "",
      gotoTerm: "",
      isMouseDown: 0,
      scrollTop: 0,
      areaData: [], // example: { type: "LEAF", x: 0, y: 0, w: 0, h: 0 }
      tableData: [],
      knowledgePointsDic: null,
      testReportRecordList: null,
      showTestRecordReportDialog: false,
      testRecordReportDialogTitle: "",
      currentReport: null,
      unreadMessageCountList: [],
    };
  },
  updated() {
    console.log("updated", "Content updated.")
  },
  async created() {
    this.$emitter.on("WINDOW_SIZE_CHANGE", (arg) => {
      console.log("JETree.received event:", "WINDOW_SIZE_CHANGE", arg);
      let canvasPanel = document.getElementById("canvasPanel");
      if (canvasPanel) {
        this.resizeWindowHandler(arg[0], arg[1], arg[2]);
      }
    });

    this.options.gradeList = this.$appSetting.globalConfig.gradeList;
    this.options.termList = this.$appSetting.globalConfig.termList;
    this.options.scopeList = this.$appSetting.globalConfig.scopeList;
    this.options.lessonTypeList = this.$appSetting.globalConfig.lessonTypeList;

    this.config.leafShadowColor = this.$appSetting.getGenericValue(
      "JETREE_LEAF_SHADOW_COLOR",
      "rgba(0, 0, 0, 1)"
    );
    this.anonymousDisplayName = this.$appSetting.getGenericValue(
      "FORUM_ANONYMOUS_DISPLAYNAME",
      "Anonym"
    );
    this.config.leafShadowBlurSize = parseInt(
      this.$appSetting.getGenericValue("JETREE_LEAF_SHADOW_BLUR_SIZE", 18)
    );

    this.options.lessonTypeList.sort(function (a, b) {
      return b.value - a.value;
    });
    for (let i = 0; i < this.options.lessonTypeList.length; i++) {
      let lessonTypeData = this.options.lessonTypeList[i];
      this.config.mainBranchSetting[`T${lessonTypeData.value}`] = {
        name: lessonTypeData.name,
        title:
          lessonTypeData.name === "Junior_Course"
            ? "Junior"
            : lessonTypeData.name === "HSC_Course_Advanced"
              ? "Advanced"
              : lessonTypeData.name === "HSC_Course_Extension_1"
                ? "Ext 1"
                : "Ext 2",
        lessonType: lessonTypeData.value,
        bgColor: lessonTypeData.branchColor,
        width: this.config.branchWidth,
        startOfX: 0,
        startOfY: 0,
        lineCap: this.config.lineCap,
        lineDash: this.config.lineDash,
      };
    }
    for (let i = 0; i < this.options.scopeList.length; i++) {
      let scope = this.options.scopeList[i];
      let bgColor = scope.leaf_BG_Color || "rgba(255, 0, 255, 0.2)";
      let bgColorForHover = bgColor;
      let arr = bgColor.split(",");
      if (arr.length === 4) {
        arr[arr.length - 1] = "0.5)";
        bgColorForHover = arr.join(", ");
      }
      this.config.scopeSetting[scope.name] = {
        bgColor: bgColor,
        bgColorForHover: bgColorForHover,
        foreColor: "rgba(0, 0, 0, 1)",
        foreColorForHover: "rgba(0, 0, 0, 1)",
        font: "1em Roboto, Arial",
        padding: 10,
        lineHeight: 18,
      };
    }

    let studentId = this.$user.getUsername();
    if (studentId) {
      this.query.studentId = studentId;
    }

    this.$nextTick(async function () {
      await this.loadAllLessons();
      await this.loadStudentUnreadCountList();
      this.drawJETree(this.tableData, true);
      if (!this.isForAdmin) {
        this.loadCurrentStudentTestRecords();
        await this.getStudentProperGradeAndYear(studentId);
        await this.loadPurchasedRecords(studentId);
        await this.loadAllCourses();
      }
      this.resizeWindowHandler(this.$store.state.inMiniScreen, this.$store.state.windowWidth, this.$store.state.windowHeight);
    });
  },
  methods: {
    async loadAllLessons() {
      let res = await getData(
        `${this.controllerUrl}/getAllLessonsInGradeAndTermDictionary`,
        this.query
      );

      let resData = res.result;
      this.tableData = resData;
    },
    async loadAllCourses() {
      let res = await getData("course", {
        courseId: null,
        name: null,
        title: null,
        pageIndex: 1,
        pageSize: 9999999,
        orderBy: "CreatedTime",
        orderDirection: "ASC",
      });

      if (res.result && res.code === "200") {
        this.courseList = res.result.list;
      } else {
        this.$message.error("Fetch course data failed, error message: " + res.message);
      }
    },
    loadCurrentStudentTestRecords() {
      getData(`/testReport/getCurrentStudentTestRecords`, null, {
        isShowLoading: false,
      }).then((res) => {
        if (res.result && res.code === "200") {
          this.testReportRecordList = res.result;
        }
      });
    },
    async getStudentProperGradeAndYear(studentId) {
      let res = await getData(`classRoom/getAllActiveClassRoomsForStudent/${studentId}`);
      console.log("getStudentProperGradeAndYear", res);
      if (res.result && res.result.length) {
        this.studentInClassRooms = res.result || [];
        if (this.studentInClassRooms.length > 0) {
          let firstClassRoom = this.studentInClassRooms[0].classRoom;
          this.gotoGrade = firstClassRoom.grade;
          this.gotoTerm = firstClassRoom.term;
          this.jumpToGradeAndTermPad();
        }
      }
    },
    async loadPurchasedRecords() {
      let res = await getData(`/student/getAllPurchaseRecords`, {
        studentId: this.query.studentId,
        objectType: "Lesson",
        startExpiryTime: new Date(),
      });
      if (res.result && res.code === "200") {
        this.purchasedRecords = res.result;
      } else {
        this.$message.error(
          "Fetch purchased records failed, error message: " + res.message
        );
      }
    },
    handleMouseMoveOnCanvas(evt) {
      if (this.isMouseDown === 1) return;
      let scale = parseInt(this.config.scale);
      let canvasPanel = document.getElementById("canvasPanel");
      let canvas = evt.target;
      let rect = canvas.getBoundingClientRect();
      let x = ((evt.clientX - rect.left) / (rect.right - rect.left)) * canvas.width;
      let y = ((evt.clientY - rect.top) / (rect.bottom - rect.top)) * canvas.height;
      x = x / scale;
      y = y / scale;
      let map = this.areaData.filter((e) => e.type === "LEAF");
      let itemData = null;
      let reDraw = false;
      let hover = false;

      if (!this.isForAdmin) {
        map = map.concat(this.areaData.filter((e) => e.type === "GRADE_TERM_PAD"));
      }

      for (let i = map.length - 1, item; (item = map[i]); i--) {
        if (
          x >= item.x &&
          x <= item.x + item.width &&
          y >= item.y &&
          y <= item.y + item.height
        ) {
          let isValidType =
            item.type === "LEAF" || (!this.isForAdmin && item.type === "GRADE_TERM_PAD");
          hover = true;
          if (isValidType && item.data && item.isHover === false) {
            itemData = item;
            reDraw = true;
            break;
          }
        }
      }
      if (!reDraw && !hover) {
        for (let i = map.length - 1, item; (item = map[i]); i--) {
          let isValidType =
            item.type === "LEAF" || (!this.isForAdmin && item.type === "GRADE_TERM_PAD");
          if (isValidType && item.data && item.isHover) {
            itemData = item;
            reDraw = true;
            break;
          }
        }
      }
      if (reDraw && itemData && itemData.type === "LEAF") {
        this.reDrawLeaf(itemData, hover, false);
      }
      if (hover) {
        canvasPanel.style.cursor = "pointer";
        if (itemData) {
          this.config.tooltipSetting.nodeData = itemData;
          if (itemData.type === "LEAF") {
            this.showJumpingButtons(canvasPanel, itemData);
          }
        }
      } else {
        this.hideJumpingButtons(canvasPanel, itemData);
        canvasPanel.style.cursor = "auto";
        this.config.tooltipSetting.nodeData = null;
      }
      // if (hover) {
      //   let tooltipX = evt.clientX;
      //   let tooltipY = evt.clientY;
      //   console.log("handleMouseOverOnCanvas", hover, scale, x, y, itemData);
      //   if (itemData)
      //     this.showToolTip(canvas, tooltipX, tooltipY, itemData.data);
      //   else this.relocateToolTip(canvas, tooltipX, tooltipY);
      // } else {
      //   this.hideToolTip();
      // }
    },
    showJumpingButtons(canvasPanel, itemData) {
      var y11 = canvasPanel.scrollTop;
      var x11 = canvasPanel.scrollLeft;

      if (this.hasSameKnowledgePointLesson("previous", itemData.data)) {
        this.$refs.previousButton.$el.style.left = `${itemData.x - x11 - 38}px`;
        this.$refs.previousButton.$el.style.top = `${itemData.y - y11 + (itemData.height - 38) / 2
          }px`;
        this.$refs.previousButton.$el.style.display = "flex";
      }
      if (this.hasSameKnowledgePointLesson("next", itemData.data)) {
        this.$refs.nextButton.$el.style.left = `${itemData.x - x11 - 11 + this.config.leafWidth
          }px`;
        this.$refs.nextButton.$el.style.top = `${itemData.y - y11 + (itemData.height - 38) / 2
          }px`;
        this.$refs.nextButton.$el.style.display = "flex";
      }
    },
    hideJumpingButtons() {
      this.$refs.previousButton.$el.style.display = "none";
      this.$refs.nextButton.$el.style.display = "none";
    },
    mouseDownEventForCanvas(evt) {
      if (evt.button !== 0) {
        evt.preventDefault();
        return;
      }
      if (evt.target.tagName.toLocaleLowerCase() !== "canvas") {
        evt.preventDefault();
        return;
      }
      let t = 0;
      if (t) {
        this.isMouseDown = 1;
        this.config.startDragOffsetX = evt.clientX - this.config.startOfX;
        this.config.startDragOffsetY = evt.clientY - this.config.startOfY;
      }
      console.log("mouseDownEventForCanvas", this.config.tooltipSetting.nodeData);
      if (this.config.tooltipSetting.nodeData) {
        if (this.config.tooltipSetting.nodeData.type === "LEAF") {
          this.handleClickLeaf(this.config.tooltipSetting.nodeData);
        } else if (this.config.tooltipSetting.nodeData.type === "GRADE_TERM_PAD") {
          this.handleClickTermPad(this.config.tooltipSetting.nodeData);
        }
      }
    },
    mouseUpEventForCanvas() {
      this.isMouseDown = 0;
    },
    mouseMoveEventForCanvas(evt) {
      if (this.isMouseDown === 1) {
        this.config.startOfX = evt.clientX - this.config.startDragOffsetX;
        this.config.startOfY = evt.clientY - this.config.startDragOffsetY;
        this.drawJETree(this.tableData, false);
      }
    },
    handleContextMenuForCanvas(evt) {
      evt.preventDefault();
    },
    handleZoomIn() {
      let scale = parseInt(this.config.scale);
      scale = scale + 1;
      if (scale > 3) {
        scale = 3;
      }
      this.config.scale = scale;
      this.drawJETree(this.tableData, true);
      this.jumpToGradeAndTermPad();
    },
    handleZoomOut() {
      let scale = parseInt(this.config.scale);
      scale = scale - 1;
      if (scale < 1) {
        scale = 1;
      }
      this.config.scale = scale;
      this.drawJETree(this.tableData, true);
      this.jumpToGradeAndTermPad();
    },
    drawJETree(lessonDic, recenter = false) {
      let count = 0;
      let index = 0;
      let scale = parseInt(this.config.scale);
      let startOfX = this.config.startOfX;
      let startOfY = this.config.startOfY;
      let branchStartOfX = 0;
      let treeWidth = 0;
      let branchWidth = 0;
      let branchHeight = 0;
      let gapOf2Branches = 0;
      let gradeTermPadWidth = 0;
      let sortedGradeAndTermNameList = [];
      let leafWidth = this.config.leafWidth;
      let curvedLineHeight = this.config.curvedLineHeight;
      let branchPadding = this.config.branchPadding;
      let leftPaddingToBranch = this.config.leftPaddingToBranch;
      let rightPaddingToBranch = this.config.rightPaddingToBranch;
      let bottomPaddingToLeaf = this.config.bottomPadding;
      let bottomPaddingToPad = this.config.gradeTermPadSetting.bottomPadding;
      let paddingTopToLeafBottom = this.config.leafBranchSetting.paddingTopToLeafBottom;
      let paddingTopForTriangle = paddingTopToLeafBottom;
      let watermarkRadius = 100;

      for (let prop in lessonDic) {
        sortedGradeAndTermNameList.push(prop);
        count = count + 1;
      }
      sortedGradeAndTermNameList.sort(function (a, b) {
        let y1 = a.replace(/[^\d]*/g, "");
        let y2 = b.replace(/[^\d]*/g, "");
        return parseInt(y2) - parseInt(y1);
      });

      let canvasPanel = document.getElementById("canvasPanel");
      let jeTreeCanvas = document.getElementById("jeTreeCanvas");
      canvasPanel.style.height = `${this.tableMaxHeight}px`;
      let ctx = jeTreeCanvas.getContext("2d");
      ctx.save();

      let branchCountInGlobal = 3;
      branchWidth =
        2 * leafWidth +
        leftPaddingToBranch +
        this.config.branchWidth +
        rightPaddingToBranch;
      treeWidth =
        branchCountInGlobal * branchWidth + (branchCountInGlobal - 1) * branchPadding;
      let canvasWidth =
        Math.max(
          canvasPanel.offsetWidth,
          (treeWidth + watermarkRadius * 2 + 40) * scale
        ) - 20;
      if (recenter) {
        startOfX = (canvasWidth - treeWidth * scale) / (2 * scale);
        console.log("startOfX", startOfX, canvasWidth, treeWidth, treeWidth * scale);
        if (startOfX < 0) {
          startOfX = 0;
        }
        this.config.startOfX = startOfX;
      }

      // initialize start of X for each branch
      branchStartOfX = startOfX;
      for (let i = 0; i < this.options.lessonTypeList.length; i++) {
        let lessonTypeData = this.options.lessonTypeList[i];
        let branchConfig = this.config.mainBranchSetting[`T${lessonTypeData.value}`];
        branchConfig.startOfX = branchStartOfX;
        branchConfig.startOfY = startOfY;
        branchStartOfX += branchWidth + branchPadding;
      }
      gapOf2Branches = branchWidth + branchPadding;
      this.config.mainBranchSetting["T1"].startOfX = this.config.mainBranchSetting[
        "T3"
      ].startOfX;

      jeTreeCanvas.width = canvasWidth;
      jeTreeCanvas.height = 1100 * count * scale;
      ctx.clearRect(0, 0, jeTreeCanvas.width, jeTreeCanvas.height);
      ctx.scale(scale, scale);
      this.areaData = [];

      // draw watermark
      // let centerX = canvasWidth / 2;
      // let centerY = jeTreeCanvas.height / 2 - watermarkRadius + 2;
      // this.drawWatermark(ctx, watermarkRadius, centerX, centerY);
      this.drawWatermark(
        ctx,
        watermarkRadius,
        canvasWidth - watermarkRadius * 1.5,
        watermarkRadius
      );

      for (const gradeAndTermName of sortedGradeAndTermNameList) {
        index = index + 1;
        let x = startOfX;
        let y = startOfY;
        let leafMetrics = { x: 0, y: 0, width: 0, height: 0 };
        let gradeAndTermPadMetrics = { x: 0, y: 0, width: 0, height: 0 };
        let groundMetrics = { x: 0, y: 0, width: 0, height: 0 };
        let branchMetrics = { x: 0, y: 0, width: 0, height: 0 };
        let text = "";
        let scope = "";
        let branchCount = 0;
        let branchType = 1;
        let maxLeavesCount = 0;
        let arr = gradeAndTermName.split("-");
        let year = arr[0];
        let term = arr[1];
        let curvedPoint = null;
        let lessonList = lessonDic[gradeAndTermName];
        let branchPoints = [];
        let lessonTypeDataList = {
          T1: { name: "", value: 0, index: 0, count: 0 },
        };
        let lessonTypeDataKeyList = [];
        let firstLessonTypeData = null;
        let _this = this;
        leafMetrics = { width: 0, height: 0 };

        lessonTypeDataList = {};
        for (let i = 0; i < lessonList.length; i++) {
          let lessonData = lessonList[i];
          let lessonTypePropName = `T${lessonData.lessonTypeValue}`;
          if (!lessonTypeDataList[lessonTypePropName]) {
            let branchConfig = this.config.mainBranchSetting[lessonTypePropName];
            let isFirst = this.isFirstForLessonType(
              branchConfig.lessonType,
              gradeAndTermName,
              sortedGradeAndTermNameList,
              lessonDic
            );
            lessonTypeDataList[lessonTypePropName] = {
              key: lessonTypePropName,
              name: branchConfig.name,
              lessonType: branchConfig.lessonType,
              branchConfig: branchConfig,
              isFirst: isFirst,
              index: 0,
              count: 1,
              x: 0,
              y: 0,
              leafMetrics: leafMetrics,
            };
          } else {
            lessonTypeDataList[lessonTypePropName].count += 1;
          }

          maxLeavesCount = Math.max(
            maxLeavesCount,
            lessonTypeDataList[lessonTypePropName].count
          );
        }

        lessonTypeDataKeyList = Object.keys(lessonTypeDataList);
        branchCount = lessonTypeDataKeyList.length;
        // T4 -> T3 -> T2 -> T1
        lessonTypeDataKeyList.sort(function (a, b) {
          let ac = _this.config.mainBranchSetting[a];
          let bc = _this.config.mainBranchSetting[b];
          return bc.lessonType - ac.lessonType;
        });

        let drawBranchesKeyList = lessonTypeDataKeyList;
        if (
          lessonTypeDataKeyList.indexOf("T2") >= 0 ||
          lessonTypeDataKeyList.indexOf("T3") >= 0 ||
          lessonTypeDataKeyList.indexOf("T4") >= 0
        ) {
          if (lessonTypeDataKeyList.indexOf("T2") < 0) drawBranchesKeyList.push("T2");
          if (lessonTypeDataKeyList.indexOf("T3") < 0) drawBranchesKeyList.push("T3");
          if (lessonTypeDataKeyList.indexOf("T4") < 0) drawBranchesKeyList.push("T4");
        }

        firstLessonTypeData = lessonTypeDataList[lessonTypeDataKeyList[0]];

        // reverse the array
        lessonList.sort(function (a, b) {
          return b.displayOrder - a.displayOrder;
        });

        branchType = 1;
        let branchesAfterward = this.howManyBranchesAfterward(
          gradeAndTermName,
          sortedGradeAndTermNameList,
          lessonDic
        );
        if (branchesAfterward === 1 && branchCount == 2) {
          branchType = 2;
        } else if (branchesAfterward > 1 && branchCount == 3) {
          branchType = 3;
        }

        gradeTermPadWidth =
          drawBranchesKeyList.length * branchWidth +
          (drawBranchesKeyList.length - 1) * branchPadding;
        x = firstLessonTypeData.branchConfig.startOfX; // lessonTypeDataKeyList[T2, T1], firstLessonTypeData is T2
        let gradeAndTermPadX = x;
        if (
          branchCount == 2 &&
          lessonTypeDataKeyList[0] === "T2" &&
          lessonTypeDataKeyList[1] === "T1"
        ) {
          branchType = 2;
          gradeAndTermPadX = this.config.mainBranchSetting["T3"].startOfX;
        }
        // final decision, 03/10/2022
        if (lessonTypeDataKeyList[0] !== "T1") {
          gradeTermPadWidth = 3 * branchWidth + 2 * branchPadding;
          gradeAndTermPadX = this.config.mainBranchSetting["T4"].startOfX;
        }

        gradeAndTermPadMetrics = this.drawGradeTermPad(
          ctx,
          index,
          year,
          term,
          gradeAndTermPadX,
          y,
          gradeTermPadWidth,
          gradeAndTermName,
          false
        );

        y = y + gradeAndTermPadMetrics.height + bottomPaddingToPad;

        for (let p in lessonTypeDataList) {
          lessonTypeDataList[p].y = y;
          lessonTypeDataList[p].leafMetrics = leafMetrics;
        }

        // draw leaves
        for (let i = 0; i < lessonList.length; i++) {
          let lessonData = lessonList[i];
          let lessonTypePropName = `T${lessonData.lessonTypeValue}`;
          let lessonTypeData = lessonTypeDataList[lessonTypePropName];
          let leafIndex = lessonTypeData.index;
          let branchStemWidth = lessonTypeData.branchConfig.width;
          // let startIndex = maxLeavesCount - lessonTypeData.count;
          let startIndex = 0;
          let isLeft = 0;
          lessonData.index = i + 1;
          leafMetrics = lessonTypeData.leafMetrics;
          branchStartOfX = lessonTypeData.branchConfig.startOfX;
          text = lessonData.name;
          scope = lessonData.scope;
          y = lessonTypeData.y;

          if (leafIndex % 2 === 0) {
            isLeft = 1;
            x = branchStartOfX;
          } else {
            isLeft = 0;
            x =
              branchStartOfX +
              leafWidth +
              leftPaddingToBranch +
              branchStemWidth +
              rightPaddingToBranch;
          }

          // add leaf's height, padding
          y = y + leafMetrics.height + bottomPaddingToLeaf;

          if (
            i > 0 &&
            branchType > 1 &&
            lessonTypeDataKeyList[0] === "T2" &&
            lessonTypeDataKeyList[1] === "T1" &&
            lessonData.lessonTypeValue != lessonList[i - 1].lessonTypeValue
          ) {
            let lastLessonTypeData =
              lessonTypeDataList[`T${lessonList[i - 1].lessonTypeValue}`];
            y =
              lastLessonTypeData.y +
              lastLessonTypeData.leafMetrics.height +
              bottomPaddingToLeaf +
              paddingTopForTriangle;
            curvedPoint = {
              x: lastLessonTypeData.startOfX + leafWidth + leftPaddingToBranch,
              y: y,
            };

            y = y + curvedLineHeight + paddingTopForTriangle;
          }

          if (leafIndex >= startIndex) {
            let scopeConfig = this.config.scopeSetting[scope];
            leafMetrics = this.drawLeaf(
              ctx,
              scopeConfig,
              x,
              y,
              text,
              lessonData,
              isLeft,
              i + 1,
              false,
              false,
              false
            );
          }

          lessonTypeData.index = leafIndex + 1;
          lessonTypeData.y = y;
          lessonTypeData.leafMetrics = leafMetrics;
        }

        for (let p in lessonTypeDataList) {
          y = Math.max(y, lessonTypeDataList[p].y);
        }

        x = startOfX;
        y = y + leafMetrics.height + this.config.groundSetting.paddingTop;

        if (index === count) {
          x = this.config.mainBranchSetting["T1"].startOfX;
          groundMetrics = this.drawGround(
            ctx,
            x,
            y,
            gradeAndTermPadMetrics.width,
            gradeAndTermPadMetrics.height * 5,
            gradeAndTermName
          );
        } else {
          groundMetrics = {
            x: startOfX,
            y: y,
            width: gradeAndTermPadMetrics.width,
            height: 0,
          };
        }

        for (let k = 0; k < lessonTypeDataKeyList.length; k++) {
          let lessonTypePropName = lessonTypeDataKeyList[k];
          let branchConfig = this.config.mainBranchSetting[lessonTypePropName];
          let lessonTypeData = lessonTypeDataList[lessonTypePropName];
          if (lessonTypeData && lessonTypeData.count) {
            branchConfig.lineDash = this.config.lineDash;
          } else {
            branchConfig.lineDash = this.config.lineDashForEmptyBranch;
          }
        }

        for (let k = 0; k < lessonTypeDataKeyList.length; k++) {
          if (branchType == 2 && k > 0) break;
          let lessonTypePropName = lessonTypeDataKeyList[k];
          let branchConfig = this.config.mainBranchSetting[lessonTypePropName];
          let firstPoint = {
            x: branchConfig.startOfX + leafWidth + leftPaddingToBranch,
            y: gradeAndTermPadMetrics.y + gradeAndTermPadMetrics.height,
          };
          branchHeight = y - firstPoint.y + groundMetrics.height / 2;
          branchMetrics = this.drawMainBranch(
            ctx,
            branchType === 2 ? 3 : branchType,
            branchConfig,
            firstPoint,
            branchWidth,
            branchHeight,
            curvedPoint,
            curvedLineHeight,
            gradeAndTermName,
            lessonTypeDataKeyList
          );
        }

        console.log(
          "gradeAndTermPadMetrics",
          gradeAndTermPadMetrics,
          "branchMetrics",
          branchMetrics,
          "groundMetrics",
          groundMetrics,
          "gapOf2Branches",
          gapOf2Branches,
          "branchPoints",
          branchPoints,
          "treeWidth",
          treeWidth,
          "branchHeight",
          branchHeight,
          "curvedPoint",
          curvedPoint
        );

        startOfY = y;
      }

      ctx.trimBottom();
      canvasPanel.scrollTop = this.scrollTop * scale;

      ctx.restore();
    },
    isFirstForLessonType(lessonType, gradeAndTermName, sortedKeys, lessonDic) {
      let times = 0;
      let pos = sortedKeys.indexOf(gradeAndTermName);
      if (pos >= 0) {
        for (let i = pos + 1; i < sortedKeys.length; i++) {
          if (times > 0) break;
          let lessonList = lessonDic[sortedKeys[i]];
          if (lessonList.some((e) => e.lessonTypeValue == lessonType)) {
            times++;
          }
        }
      }

      return times < 1;
    },
    howManyBranchesAfterward(gradeAndTermName, sortedKeys, lessonDic) {
      let lessonTypes = [];
      let pos = sortedKeys.indexOf(gradeAndTermName);
      if (pos >= 0) {
        for (let i = pos + 1; i < sortedKeys.length; i++) {
          let lessonList = lessonDic[sortedKeys[i]];
          for (let j = 0; j < lessonList.length; j++) {
            if (lessonTypes.indexOf(lessonList[j].lessonType) < 0) {
              lessonTypes.push(lessonList[j].lessonType);
            }
          }
        }
      }

      return lessonTypes.length;
    },
    drawGround(ctx, x, y, width, height, data) {
      let originalX = x;
      let originalY = y;
      let angle = this.config.groundSetting.angle;
      let groundWidth = width;
      let groundHeight = height;

      let x1 = Math.abs(Math.round(Math.sin(angle) * groundHeight));
      let realWidth = groundWidth - x1;

      let y1 = 0;
      let x2 = x1 + realWidth;
      let y2 = 0;
      let x3 = realWidth;
      let y3 = Math.abs(Math.round(Math.cos(angle) * groundHeight));
      let x4 = 0;
      let y4 = y3;

      ctx.beginPath();
      ctx.moveTo(x1 + x, y1 + y);
      ctx.lineTo(x2 + x, y2 + y);
      ctx.lineTo(x3 + x, y3 + y);
      ctx.lineTo(x4 + x, y4 + y);
      ctx.fillStyle = this.config.groundSetting.bgColor;
      ctx.fill();

      let groundMetrics = {
        type: "GROUND",
        x: originalX,
        y: originalY,
        inputWidth: width,
        inputHeight: height,
        height: y4 - y1,
        width: x4 - x2,
        args: arguments,
        data: data,
      };

      this.areaData.push(groundMetrics);

      return groundMetrics;
    },
    drawGradeTermPad(ctx, index, grade, term, x, y, treeWidth, data, isHover) {
      let originalX = x;
      let originalY = y;
      let width = treeWidth;
      let height = this.config.gradeTermPadSetting.height;
      let radius = this.config.radius;
      let gradeStr = this.$formatter.formatGrade(grade);
      ctx.fillStyle = this.config.gradeTermPadSetting.bgColor;
      ctx.roundRect(x, y, width, height, radius, true);

      let text = `${gradeStr} ${term} TEST`.toUpperCase();
      if (index === 1) {
        text = "HSC";
      }
      ctx.fillStyle = this.config.gradeTermPadSetting.foreColor;
      ctx.font = this.config.gradeTermPadSetting.font;
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      x = x + width / 2;
      y = y + height / 2;
      ctx.fillText(text, x, y);

      let padMetrics = {
        type: "GRADE_TERM_PAD",
        x: originalX,
        y: originalY,
        width: width,
        height: height,
        args: arguments,
        gradeAndTerm: `${grade}-${term}`,
        grade: grade,
        term: term,
        data: data,
        isHover: isHover,
      };

      this.areaData.push(padMetrics);

      return padMetrics;
    },
    drawMainBranch(
      ctx,
      branchType,
      branchConfig,
      initialPoint,
      branchWidth,
      branchHeight,
      curvedPoint,
      curvedLineHeight,
      data
    ) {
      let padBorderWidth = 1;
      let branchStemWidth = branchConfig.width;
      let x = initialPoint.x + branchStemWidth / 2;
      let y = initialPoint.y + branchStemWidth / 2 + padBorderWidth;
      let x2, y2;
      let branchPadding = this.config.branchPadding;
      let width = branchWidth + branchPadding;

      if (branchType === 1) {
        ctx.save();
        ctx.beginPath();
        ctx.lineWidth = branchStemWidth;
        ctx.lineCap = branchConfig.lineCap;
        ctx.setLineDash(branchConfig.lineDash);
        ctx.strokeStyle = branchConfig.bgColor;
        ctx.moveTo(x, y); // 1px for pad border

        x2 = x;
        y2 = y + branchHeight - branchStemWidth - padBorderWidth;
        ctx.lineTo(x2, y2);

        ctx.stroke();
        ctx.restore();
      } else if (branchType === 2) {
        branchConfig = this.config.mainBranchSetting["T1"];
        let branchConfig2 = this.config.mainBranchSetting["T2"];
        let branchConfig3 = this.config.mainBranchSetting["T3"];

        let curvedY = curvedPoint.y;

        ctx.save();
        ctx.beginPath();
        ctx.lineWidth = branchStemWidth;
        ctx.lineCap = branchConfig2.lineCap;
        ctx.setLineDash(branchConfig2.lineDash);
        ctx.strokeStyle = branchConfig2.bgColor;
        ctx.moveTo(x, y); // 1px for pad border

        x2 = x;
        y2 = curvedY;
        ctx.lineTo(x2, y2);

        x2 = x - width / 2;
        y2 = curvedY + curvedLineHeight;
        ctx.lineTo(x2, y2);
        ctx.stroke();

        ctx.beginPath();
        ctx.lineCap = branchConfig3.lineCap;
        ctx.setLineDash(branchConfig3.lineDash);
        ctx.strokeStyle = branchConfig3.bgColor;
        x2 = x - width / 2;
        y2 = curvedY + curvedLineHeight;
        ctx.moveTo(x2, y2);

        x2 = x - width;
        y2 = curvedY;
        ctx.lineTo(x2, y2);

        y2 = y;
        ctx.lineTo(x2, y2);
        ctx.stroke();

        // the last line connecting the T1
        ctx.beginPath();
        ctx.lineCap = branchConfig.lineCap;
        ctx.setLineDash(branchConfig.lineDash);
        ctx.strokeStyle = branchConfig.bgColor;
        x2 = x - width / 2;
        y2 = curvedY + curvedLineHeight;
        ctx.moveTo(x2, y2);
        y2 = y + branchHeight - branchStemWidth - padBorderWidth;
        ctx.lineTo(x2, y2);
        ctx.stroke();
        ctx.restore();
      } else if (branchType === 3) {
        // sharp like a 2*Y
        branchConfig = this.config.mainBranchSetting["T1"];
        let branchConfig2 = this.config.mainBranchSetting["T2"];
        let branchConfig3 = this.config.mainBranchSetting["T3"];
        let branchConfig4 = this.config.mainBranchSetting["T4"];

        let curvedY = curvedPoint.y;

        ctx.save();
        ctx.beginPath();
        ctx.strokeStyle = branchConfig2.bgColor;
        ctx.lineWidth = branchStemWidth;
        ctx.lineCap = branchConfig2.lineCap;
        ctx.setLineDash(branchConfig2.lineDash);
        ctx.moveTo(x, y); // 1px for pad border

        x2 = x;
        y2 = curvedY;
        ctx.lineTo(x2, y2);

        x2 = x - width;
        y2 = curvedY + curvedLineHeight;
        ctx.lineTo(x2, y2);
        ctx.stroke();

        ctx.beginPath();
        ctx.lineCap = branchConfig3.lineCap;
        ctx.setLineDash(branchConfig3.lineDash);
        ctx.strokeStyle = branchConfig3.bgColor;
        x2 = x - width;
        y2 = curvedY + curvedLineHeight;
        ctx.moveTo(x2, y2);

        y2 = y;
        ctx.lineTo(x2, y2);
        ctx.stroke();

        ctx.beginPath();
        ctx.lineCap = branchConfig4.lineCap;
        ctx.setLineDash(branchConfig4.lineDash);
        ctx.strokeStyle = branchConfig4.bgColor;
        x2 = x - width;
        y2 = curvedY + curvedLineHeight;
        ctx.moveTo(x2, y2);

        x2 = x - 2 * width;
        y2 = curvedY;
        ctx.lineTo(x2, y2);

        y2 = y;
        ctx.lineTo(x2, y2);
        ctx.stroke();

        // the last line connecting the T1
        ctx.beginPath();
        ctx.lineCap = branchConfig.lineCap;
        ctx.setLineDash(branchConfig.lineDash);
        ctx.strokeStyle = branchConfig.bgColor;
        x2 = x - width;
        y2 = curvedY + curvedLineHeight;
        ctx.moveTo(x2, y2);
        y2 = y + branchHeight - branchStemWidth - padBorderWidth;
        ctx.lineTo(x2, y2);
        ctx.stroke();
        ctx.restore();
      }

      let branchMetrics = {
        type: "MAIN_BRANCH",
        x: x,
        y: y,
        // width: branchStemWidth,
        width: width,
        height: branchHeight,
        args: arguments,
        data: data,
      };

      this.areaData.push(branchMetrics);

      return branchMetrics;
    },
    reDrawLeaf(itemData, hover, shadow) {
      let scale = parseInt(this.config.scale);
      let jeTreeCanvas = document.getElementById("jeTreeCanvas");
      let ctx = jeTreeCanvas.getContext("2d");
      ctx.save();
      ctx.scale(scale, scale);

      let args = [];
      for (let p in itemData.args) {
        args.push(itemData.args[p]);
      }

      args[args.length - 3] = true;
      args[args.length - 2] = hover;
      args[args.length - 1] = shadow;

      let pos = this.areaData.findIndex((e) => e === itemData);
      if (pos >= 0) this.areaData.splice(pos, 1);

      this.drawLeaf.apply(this, args);

      ctx.restore();
    },
    drawLeaf(
      ctx,
      scopeConfig,
      x,
      y,
      text,
      data,
      isLeft,
      index,
      clearArea = false,
      isHover = false,
      drawShadow = false
    ) {
      let originalX = x;
      let originalY = y;
      let width = this.config.leafWidth;
      let radius = this.config.radius;
      let padding = scopeConfig.padding + radius;
      let lineHeight = scopeConfig.lineHeight;
      let leafShadowBlurSize = this.config.leafShadowBlurSize;
      let leafShadowColor = this.config.leafShadowColor;
      let extraWidth = leafShadowBlurSize + 1;

      ctx.save();
      ctx.font = scopeConfig.font;
      let lines = this.calculateWordsLines(ctx, text, width - 2 * padding);
      let fontHeight = lines.length * lineHeight;
      let totalHeight = fontHeight + padding;
      if (totalHeight < this.config.leafMinHeight) {
        totalHeight = this.config.leafMinHeight;
      }

      if (clearArea) {
        ctx.clearRect(
          x - extraWidth,
          y - extraWidth,
          width + 2 * extraWidth,
          totalHeight + 2 * extraWidth
        );
      }

      this.drawBranchForLeaf(
        ctx,
        isLeft,
        originalX,
        originalY,
        width,
        totalHeight,
        scopeConfig,
        clearArea
      );

      ctx.fillStyle = isHover ? scopeConfig.bgColorForHover : scopeConfig.bgColor;
      if (drawShadow) {
        ctx.shadowBlur = leafShadowBlurSize;
        ctx.shadowColor = leafShadowColor;
      }
      ctx.roundRect(x, y, width, totalHeight, radius, true, false);
      ctx.restore();

      // draw unread message count dot
      let unreadCount = this.getStudentUnreadForumMessageCount(data.lessonId);
      if (unreadCount) {
        ctx.save();
        ctx.fillStyle = "#fa5151"; //red
        ctx.beginPath();
        ctx.arc(x + width, y, 8, 0, Math.PI * 2, true);
        ctx.closePath();
        ctx.fill();
        ctx.restore();
      }
      // end of drawing unread message count dot

      ctx.fillStyle = isHover ? scopeConfig.foreColorForHover : scopeConfig.foreColor;
      ctx.font = scopeConfig.font;
      ctx.textAlign = this.config.leafTextAlign;
      ctx.textBaseline = "top";
      y = y + (totalHeight - fontHeight) / 2 + radius;
      if (this.config.leafTextAlign === "center") {
        x = x + width / 2;
      } else {
        x = x + padding;
      }
      ctx.fillMultiText(x, y, lines, lineHeight);
      let leafMetrics = {
        type: "LEAF",
        x: originalX,
        y: originalY,
        width: width,
        height: totalHeight,
        index: index,
        args: arguments,
        data: data,
        isHover: isHover,
      };

      this.areaData.push(leafMetrics);

      return leafMetrics;
    },
    drawBranchForLeaf(ctx, isLeft, x, y, width, height, scopeConfig, clearArea = false) {
      let leafX = x;
      let leafY = y;
      let leafWidth = width;
      let leafHeight = height;
      let leftPaddingToBranch = this.config.leftPaddingToBranch;
      let rightPaddingToBranch = this.config.rightPaddingToBranch;
      let paddingTopToLeafBottom = this.config.leafBranchSetting.paddingTopToLeafBottom;
      let cpx = 0;
      let cpy = 0;
      let cp1x = 0;
      let cp1y = 0;
      let cp2x = 0;
      let cp2y = 0;
      // draw link string
      if (isLeft === 1) {
        cpx = leafX + leafWidth;
        cpy = leafY + leafHeight / 2;

        cp1x = cpx + leftPaddingToBranch;
        cp1y = cpy;

        cp2x = cpx + leftPaddingToBranch;
        cp2y = leafY + leafHeight + paddingTopToLeafBottom;
      } else {
        cpx = leafX;
        cpy = leafY + leafHeight / 2;

        cp1x = cpx - rightPaddingToBranch;
        cp1y = cpy;

        cp2x = cpx - rightPaddingToBranch;
        cp2y = leafY + leafHeight + paddingTopToLeafBottom;
      }

      if (clearArea) {
        ctx.clearRect(cpx, cpy, cp2x - cpx, cp2y - cpy);
      }

      ctx.beginPath();
      ctx.moveTo(cpx, cpy);
      ctx.bezierCurveTo(cpx, cpy, cp1x, cp1y, cp2x, cp2y);
      ctx.lineWidth = this.config.leafBranchSetting.width;
      ctx.strokeStyle = scopeConfig.bgColor;
      ctx.stroke();
    },
    drawWatermark(ctx, radius, centerX, centerY) {
      let alpha = 0.5;
      ctx.beginPath();
      ctx.fillStyle = `rgb(184, 217, 254, ${alpha})`;
      ctx.arc(centerX, centerY, radius, 0, 2 * Math.PI, false);
      ctx.fill();
      ctx.strokeStyle = "#ffffff";
      ctx.font = "bold 48px Calibri";
      ctx.textAlign = "center";
      ctx.fillStyle = `rgb(255, 255, 255, ${alpha})`;

      ctx.textBaseline = "middle";

      ctx.fillText("JETree", centerX, centerY);
      ctx.stroke();
    },
    showToolTip(ctx, centerX, centerY, data) {
      let tooltipSetting = this.config.tooltipSetting;
      let tipElm = tooltipSetting.element;
      if (!tipElm) {
        tipElm = document.createElement("div");
        // let arrowElm = document.createElement("span");
        let contentElm = document.createElement("div");
        let borderWidth = 10;
        ctx.parentNode.appendChild(tipElm);
        // tipElm.appendChild(arrowElm);
        tipElm.appendChild(contentElm);

        // arrowElm.style.cssText = `width:0;height:0;border-left:${borderWidth}px solid transparent;border-right:${borderWidth}px solid transparent;border-bottom:${borderWidth}px solid ${tooltipSetting.bgColor};`;
        contentElm.style.cssText = `margin-top:${borderWidth}px;display:block;padding:${tooltipSetting.padding}px;background:${tooltipSetting.bgColor};pointer-events:none;min-width:${tooltipSetting.width}px;font:${tooltipSetting.font};color:${tooltipSetting.foreColor};`;
        tipElm.style.cssText = `position:fixed;display:none;`;

        tooltipSetting.element = tipElm;
      }
      tooltipSetting.nodeData = data;

      let des = data.description || "No Description";
      tipElm.lastChild.innerHTML = des.replace(/\r/gi, "").replace(/\n/gi, "<br>");
      tipElm.style.display = "inline-block";
      tipElm.style.left = centerX + 8 + "px";
      tipElm.style.top = centerY + 8 + "px";
    },
    relocateToolTip(ctx, centerX, centerY) {
      let tooltipSetting = this.config.tooltipSetting;
      let tipElm = tooltipSetting.element;
      if (tipElm) {
        tipElm.style.left = centerX + 8 + "px";
        tipElm.style.top = centerY + 8 + "px";
      }
    },
    hideToolTip() {
      let tooltipSetting = this.config.tooltipSetting;
      tooltipSetting.nodeData = null;
      let tipElm = tooltipSetting.element;
      if (tipElm) {
        tipElm.style.display = "none";
        tipElm.lastChild.innerHTML = "";
      }
    },
    calculateWordsLines(ctx, text, maxWidth) {
      let lines = [],
        words = text
          .replace(/\n\n/g, " ` ")
          .replace(/(\n\s|\s\n)/g, "\r")
          .replace(/\s\s/g, " ")
          .replace("`", " ")
          .replace(/(\r|\n)/g, " " + " ")
          .split(" "),
        space = ctx.measureText(" ").width,
        width = 0,
        line = "",
        word = "",
        len = words.length,
        w = 0,
        i;
      for (i = 0; i < len; i++) {
        word = words[i];
        w = word ? ctx.measureText(word).width : 0;
        if (w) {
          width = width + space + w;
        }
        if (w > maxWidth) {
          return [];
        } else if (w && width < maxWidth) {
          line += (i ? " " : "") + word;
        } else {
          !i || lines.push(line !== "" ? line.trim() : "");
          line = word;
          width = w;
        }
      }
      if (len !== i || line !== "") {
        lines.push(line);
      }
      return lines;
    },
    handleSelectGrade(grade) {
      this.gotoGrade = grade;
      this.jumpToGradeAndTermPad();
    },
    handleSelectTerm(term) {
      this.gotoTerm = term;
      this.jumpToGradeAndTermPad();
    },
    jumpToGradeAndTermPad(behavior = "smooth") {
      if (this.gotoGrade && this.gotoTerm) {
        let gradeAndTerm = `${this.gotoGrade}-${this.gotoTerm}`.toLocaleLowerCase();
        let pad = this.areaData.find(
          (e) =>
            e.type === "GRADE_TERM_PAD" &&
            e.gradeAndTerm.toLocaleLowerCase() === gradeAndTerm
        );
        console.log("jumpToGradeAndTermPad", pad, gradeAndTerm);
        if (pad) {
          this.jumpToElement(pad.y, behavior);
        }
      }
    },
    jumpToLeaf(leafData, behavior = "smooth") {
      if (leafData) {
        let lessonId = leafData.lessonId;
        let leaf = this.areaData.find(
          (e) => e.type === "LEAF" && e.data.lessonId === lessonId
        );
        console.log("jumpToLeaf", leafData, behavior);
        if (leaf) {
          let visibleCanvasHeight = document.getElementById("canvasPanel").clientHeight;
          this.jumpToElement(leaf.y - visibleCanvasHeight / 2 + leaf.height, behavior);
        }
      }
    },
    jumpToElement(y, behavior = "smooth") {
      if (y) {
        let scale = parseInt(this.config.scale);
        let canvasPanel = document.getElementById("canvasPanel");
        this.scrollTop = y;
        canvasPanel.scrollTo({
          top: y * scale,
          behavior: behavior,
        });
      }
    },
    resizeWindowHandler(inMiniScreen, windowWidth, windowHeight) {
      let reservedHeight = process.env.VUE_APP_ROLE === "admin" ? 180 : 180;
      this.tableMaxHeight = windowHeight - reservedHeight;
      this.drawJETree(this.tableData, true);
      this.jumpToGradeAndTermPad();
      console.log("JETree.resizeWindowHandler", arguments, windowHeight, this.tableMaxHeight);
    },
    handleClickTermPad(nodeData) {
      if (
        !nodeData ||
        !this.testReportRecordList ||
        this.testReportRecordList.length === 0
      )
        return;
      let termPadData = nodeData;
      let testRecord = this.testReportRecordList.find(
        (e) =>
          e.testReport.grade.toLowerCase() == termPadData.grade.toLowerCase() &&
          e.testReport.term.toLowerCase() == termPadData.term.toLowerCase()
      );

      console.log(
        "handleClickTermPad",
        this.testReportRecordList,
        termPadData,
        testRecord
      );
      if (testRecord != null) {
        this.currentReport = testRecord.testReport;
        this.testRecordReportDialogTitle = `My report for the test records '${this.currentReport.name}'`;
        this.showTestRecordReportDialog = true;
      } else {
        this.$msgbox({
          title: "Information",
          message: "No term test can be found.",
          showConfirmButton: false,
          showCancelButton: true,
          cancelButtonText: "Close",
          dangerouslyUseHTMLString: true,
          center: true,
          draggable: true,
        });
      }
    },
    handleClickLeaf(nodeData) {
      if (!nodeData) return;
      let leafData = nodeData.data;
      let showWidth = this.$widthRatio * 520;

      this.$msgbox({
        title: leafData.name,
        message: h(
          "div",
          {
            id: this.leafDialogContentId,
            style: "position:relative;text-align:left;",
          },
          this.buildLeafInfoDialog(leafData)
        ),
        customClass: "lessonOperationalDialog",
        showConfirmButton: false,
        showCancelButton: true,
        cancelButtonText: "Close",
        dangerouslyUseHTMLString: false,
        center: true,
        draggable: true,
        customStyle: `width:${showWidth}px;`,
      });
      this.hideToolTip();
    },
    async replayLesson(leafData, item) {
      let windowWidth =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      let windowHeight =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight;

      let widthPercentage = 0.6;
      let showWidth = windowWidth * widthPercentage;
      let showHeight = (showWidth * windowHeight) / windowWidth;
      let replayLink = leafData.replayLink;
      if (LessonOperationEnum.Replay_Hot === item) {
        replayLink = leafData.replayLinkForHot;
      }
      if (replayLink.indexOf("https://") < 0) {
        this.$message.warning(
          `The lesson "${leafData.name}" doesn't have replay resource, please contact your teacher.`
        );
        return;
      }
      replayLink = replayLink.replace("/view", "/preview");

      this.$msgbox({
        title: `${leafData.name} - [Replay]`,
        message: `<div style="position:relative;"><a target="_blank" href="https://www.jeeducation.com.au/" style="padding:10px 15px;right:10px;top:10px;z-index:100;position:absolute;background:#333333;color:#FFFFFF;font-size:24px;">JE Maths</a><iframe style="left:0;top:0;z-index:1;" width="100%" height="${showHeight}px" src="${replayLink}" allowfullscreen="true" webkitallowfullscreen="true" mozallowfullscreen="true"></iframe></div>`,
        showConfirmButton: false,
        showCancelButton: true,
        cancelButtonText: "Close",
        dangerouslyUseHTMLString: true,
        center: true,
        draggable: true,
        customStyle: `width:${showWidth}px;`,
      });
      this.hideToolTip();
    },
    calculateBCForRightTriangle(a, angle) {
      let radians = (Math.PI * angle) / 180;
      let b = Math.tan(radians) * a;
      let c = a / Math.cos(radians);
      return { a: a, b: b, c: c };
    },
    buildLeafInfoDialog(leafData) {
      let description = (leafData.description || "No description").replace(
        /(?:\r\n|\r|\n)/g,
        "<br>"
      );

      let elms = [];
      let hasBasicActions = false;
      let hasExtraActions = false;
      let hasReplyLink = false;
      let hasReplyLinkForHot = false;
      let hasHomeworkLink = false;
      let hasAnswerLink = false;
      let hasLinkForExtraFoundationExercise = false;
      let hasLinkForExtraDevelopmentExercise = false;
      let hasLinkForExtraEnrichmentExercise = false;

      if (leafData.replayLink && leafData.replayLink.indexOf("https://") >= 0) {
        hasBasicActions = true;
        hasReplyLink = true;
      }
      if (leafData.replayLinkForHot && leafData.replayLinkForHot.indexOf("https://") >= 0) {
        hasBasicActions = true;
        hasReplyLinkForHot = true;
      }
      if (leafData.homeworkLink && leafData.homeworkLink.indexOf("https://") >= 0) {
        hasBasicActions = true;
        hasHomeworkLink = true;
      }
      if (leafData.answerLink && leafData.answerLink.indexOf("https://") >= 0) {
        hasBasicActions = true;
        hasAnswerLink = true;
      }
      if (
        leafData.linkForExtraFoundationExercise &&
        leafData.linkForExtraFoundationExercise.indexOf("https://") >= 0
      ) {
        hasExtraActions = true;
        hasLinkForExtraFoundationExercise = true;
      }
      if (
        leafData.linkForExtraDevelopmentExercise &&
        leafData.linkForExtraDevelopmentExercise.indexOf("https://") >= 0
      ) {
        hasExtraActions = true;
        hasLinkForExtraDevelopmentExercise = true;
      }
      if (
        leafData.linkForExtraEnrichmentExercise &&
        leafData.linkForExtraEnrichmentExercise.indexOf("https://") >= 0
      ) {
        hasExtraActions = true;
        hasLinkForExtraEnrichmentExercise = true;
      }

      let descriptionHeaderElement = h(
        "h4",
        {
          style: "margin-top:10px;",
        },
        "Description"
      );
      let descriptionElement = h("div", {
        style: "padding:10px 0;",
        innerHTML: description,
      });
      elms.push(descriptionHeaderElement);
      elms.push(descriptionElement);

      // add forum link
      // load read count
      let unreadCount = this.getStudentUnreadForumMessageCount(leafData.lessonId);
      let displayValue = unreadCount > 0 ? "flex" : "none";
      let forumLinkElement = h(
        "a",
        {
          onClick: () => {
            this.clearStudentUnreadForumMessageCount(leafData.lessonId);
            this.$emitter.emit("OPEN_FORUM", [leafData.lessonId]);
          },
          title: "Review & Discuss",
          class: "active",
          style: "font-size:1.8rem;position:relative;",
          innerHTML: `💬<div style='display:${displayValue};' id='unread_forum_message_count_${leafData.lessonId}' class='badge-notification'>${unreadCount}</div>`,
        },
        ""
      );
      elms.push(
        h("div", { class: "operation", style: "text-align:right;padding-right:6px;" }, [forumLinkElement])
      );

      let operationsHeaderElement = h(
        "h4",
        { style: "margin:10px 0;" },
        "Basic Services"
      );

      if (hasBasicActions) {
        elms.push(operationsHeaderElement);
      }
      if (hasBasicActions && hasReplyLinkForHot) {
        elms.push(
          this.buildOperationItem(
            leafData,
            LessonCategoryEnum.Base,
            LessonOperationEnum.Replay_Hot,
            false,
            "POINTS_CALCULATE_DISCOUNT_FOR_REPLAY_HOT",
            "Replay(A)",
            "Replay the lesson's video online"
          )
        );
      }
      if (hasBasicActions && hasReplyLink) {
        elms.push(
          this.buildOperationItem(
            leafData,
            LessonCategoryEnum.Base,
            LessonOperationEnum.Replay,
            false,
            "POINTS_CALCULATE_DISCOUNT_FOR_REPLAY",
            "Replay(B)",
            "Replay the lesson's video online"
          )
        );
      }
      if (hasBasicActions && hasHomeworkLink) {
        elms.push(
          this.buildOperationItem(
            leafData,
            LessonCategoryEnum.Base,
            LessonOperationEnum.Homework,
            false,
            "POINTS_CALCULATE_DISCOUNT_FOR_HOMEWORK",
            "Homework",
            "View homework online"
          )
        );
      }
      if (hasBasicActions && hasAnswerLink) {
        elms.push(
          this.buildOperationItem(
            leafData,
            LessonCategoryEnum.Base,
            LessonOperationEnum.Answer,
            false,
            "POINTS_CALCULATE_DISCOUNT_FOR_ANSWER",
            "Full Solution",
            "View full solution for the homework"
          )
        );
      }

      let extraOperationHeaderElement = h(
        "h5",
        { style: "margin:10px 0;" },
        "Extra Services"
      );
      if (hasExtraActions) {
        elms.push(extraOperationHeaderElement);
      }

      if (hasExtraActions && hasLinkForExtraFoundationExercise) {
        elms.push(
          this.buildOperationItem(
            leafData,
            LessonCategoryEnum.ExtraExercise,
            LessonOperationEnum.ExtraFoundationExercise,
            true,
            "POINTS_FOR_PURCHASE_FOUNDATION_EXERCISE",
            "Extra Foundation",
            "View extra foundation exercise online"
          )
        );
      }

      if (hasExtraActions && hasLinkForExtraDevelopmentExercise) {
        elms.push(
          this.buildOperationItem(
            leafData,
            LessonCategoryEnum.ExtraExercise,
            LessonOperationEnum.ExtraDevelopmentExercise,
            true,
            "POINTS_FOR_PURCHASE_DEVELOPMENT_EXERCISE",
            "Extra Development",
            "View extra development exercise online"
          )
        );
      }

      if (hasExtraActions && hasLinkForExtraEnrichmentExercise) {
        elms.push(
          this.buildOperationItem(
            leafData,
            LessonCategoryEnum.ExtraExercise,
            LessonOperationEnum.ExtraEnrichmentExercise,
            true,
            "POINTS_FOR_PURCHASE_ENRICHMENT_EXERCISE",
            "Extra Enrichment",
            "View extra enrichment exercise online"
          )
        );
      }

      return elms;
    },
    buildOperationItem(
      leafData,
      category,
      item,
      isPoints,
      pointsOrDiscountKey,
      linkText,
      aTitle
    ) {
      let operationItemAvailable = true;
      if (!this.isForAdmin) {
        let lessonsWithSameGradeAndTerm =
          this.tableData[`${leafData.grade.toLowerCase()}-${leafData.term}`] || [];

        let studentInGradeAndTerm =
          this.studentInClassRooms.findIndex(
            (e) => {
              let ret = (e.lessonCount ||
                lessonsWithSameGradeAndTerm.length ||
                e.classRoom.lessonCount) >= leafData.displayOrder &&
                e.classRoom.lessons.findIndex((e) => e.lessonId === leafData.lessonId) >= 0;

              console.log("this.studentInClassRooms.findIndex", e.lessonCount, lessonsWithSameGradeAndTerm.length, e.classRoom.lessonCount, leafData.index, ret, (e.lessonCount ||
                lessonsWithSameGradeAndTerm.length ||
                e.classRoom.lessonCount));
              return ret;
            }

          ) >= 0;
        console.log(
          "buildOperationItem",
          this.studentInClassRooms.findIndex(
            (e) =>
              ((e.lessonCount ||
                lessonsWithSameGradeAndTerm.length ||
                e.classRoom.lessonCount) >= leafData.displayOrder &&
                e.classRoom.lessons.findIndex((e) => e.lessonId === leafData.lessonId)) >=
              0
          ),
          studentInGradeAndTerm,
          this.studentInClassRooms.filter(e => e.classRoom.gradeValue == 6),
          leafData,
          leafData.index,
          this.studentInClassRooms
        );
        if (!studentInGradeAndTerm) {
          operationItemAvailable = this.checkLessonOperationItemAvailable(
            leafData,
            category,
            item
          );
        }
      }
      let unlockData = {};
      if (!operationItemAvailable) {
        unlockData = this.calculateUnlockData(
          leafData,
          category,
          item,
          isPoints,
          pointsOrDiscountKey
        );
      }
      let linkElement = h(
        "a",
        {
          onClick: (event) =>
            this.actionLessonOperationItem(
              event,
              leafData,
              category,
              item,
              linkText,
              isPoints,
              pointsOrDiscountKey
            ),
          title: aTitle,
          class: operationItemAvailable ? "active" : "disabled",
          disabled: !operationItemAvailable,
          innerHTML: operationItemAvailable
            ? linkText
            : unlockData.discountPoints > 0
              ? `${linkText} (<del style='color:rgb(255,0,0,0.3);font-weight:bold;'>was:${unlockData.points + unlockData.discountPoints
              }</del> <b style='color:rgb(255,0,0,0.3);'> now:${unlockData.points
              }</b> points to unlock)`
              : `${linkText} (<b style='color:rgb(255,0,0,0.3);'>${unlockData.points}</b> points to unlock)`,
        },
        ""
      );
      let unlockLinkElement = h(
        "a",
        {
          onClick: (event) =>
            this.unlockLessonOperationItem(
              event,
              leafData,
              category,
              item,
              linkText,
              isPoints,
              pointsOrDiscountKey
            ),
          title: "Unlock",
          class: "active unlock",
        },
        "Unlock"
      );

      return h("div", { class: "operation", style: "position:relative;" }, [
        linkElement,
        operationItemAvailable ? null : unlockLinkElement,
      ]);
    },
    jumpToSameKnowledgePointLesson(command, leafData = null) {
      let lessonData = null;
      leafData = leafData || this.config.tooltipSetting.nodeData.data;
      if (!leafData) return;
      if (command === "next") {
        lessonData = this.getSameKnowledgePointLesson("previous", leafData);
      } else if (command === "previous") {
        lessonData = this.getSameKnowledgePointLesson("next", leafData);
      }
      if (lessonData) {
        ElMessageBox.close();
        // this.handleClickLeaf(lessonData);
        this.hideJumpingButtons();
        let itemData = this.areaData.find(
          (e) => e.type === "LEAF" && e.data.lessonId === lessonData.lessonId
        );
        this.reDrawLeaf(itemData, false, true);
        this.jumpToLeaf(lessonData);
      }
    },
    hasSameKnowledgePointLesson(command, leafData) {
      let lessonData = null;
      if (command === "next") {
        lessonData = this.getSameKnowledgePointLesson("previous", leafData);
      } else if (command === "previous") {
        lessonData = this.getSameKnowledgePointLesson("next", leafData);
      }
      return lessonData ? true : false;
    },
    getSameKnowledgePointLesson(command, leafData) {
      if (!this.knowledgePointsDic) {
        this.knowledgePointsDic = {};
        let lessonDic = this.tableData;
        for (let gradeAndTermName in lessonDic) {
          let lessonList = lessonDic[gradeAndTermName];
          for (let i = 0; i < lessonList.length; i++) {
            let lessonData = lessonList[i];
            let arr = this.knowledgePointsDic[lessonData.knowledgePoint] || [];
            arr.push(lessonData);
            this.knowledgePointsDic[lessonData.knowledgePoint] = arr;
          }
        }
      }

      let lessonList = this.knowledgePointsDic[leafData.knowledgePoint];
      let index = lessonList.findIndex((e) => e.lessonId === leafData.lessonId);
      if (command === "previous" && index < lessonList.length) {
        return lessonList[index + 1];
      }
      if (command === "next" && index > 0) {
        return lessonList[index - 1];
      }

      return null;
    },
    checkLessonOperationItemAvailable(leafData, category, item) {
      return (
        this.purchasedRecords.findIndex(
          (e) => e.objectId === leafData.lessonId && e.itemId === item
        ) >= 0
      );
    },
    async actionLessonOperationItem(
      evt,
      leafData,
      category,
      item,
      linkText,
      isPoints,
      pointsOrDiscountKey
    ) {
      if (!leafData) return;
      let operationItemAvailable = true;
      if (!this.isForAdmin) {
        let lessonsWithSameGradeAndTerm =
          this.tableData[`${leafData.grade.toLowerCase()}-${leafData.term}`] || [];

        let studentInGradeAndTerm =
          this.studentInClassRooms.findIndex(
            (e) =>
              ((e.lessonCount ||
                lessonsWithSameGradeAndTerm.length ||
                e.classRoom.lessonCount) >= leafData.displayOrder &&
                e.classRoom.lessons.findIndex((e) => e.lessonId === leafData.lessonId)) >=
              0
          ) >= 0;
        console.log(
          "buildOperationItem",
          lessonsWithSameGradeAndTerm,
          leafData,
          leafData.index
        );
        if (!studentInGradeAndTerm) {
          operationItemAvailable = this.checkLessonOperationItemAvailable(
            leafData,
            category,
            item
          );
        }
      }

      if (!operationItemAvailable) {
        let unlockResult = await this.unlockLessonOperationItem(
          evt,
          leafData,
          category,
          item,
          linkText,
          isPoints,
          pointsOrDiscountKey
        );
        if (!unlockResult) return;
      }
      switch (item) {
        case LessonOperationEnum.Replay:
        case LessonOperationEnum.Replay_Hot:
          this.replayLesson(leafData, item);
          break;
        case LessonOperationEnum.Homework:
          window.open(leafData.homeworkLink, "_blank").focus();
          break;
        case LessonOperationEnum.Answer:
          window.open(leafData.answerLink, "_blank").focus();
          break;
        case LessonOperationEnum.ExtraFoundationExercise:
          window.open(leafData.linkForExtraFoundationExercise, "_blank").focus();
          break;
        case LessonOperationEnum.ExtraDevelopmentExercise:
          window.open(leafData.linkForExtraDevelopmentExercise, "_blank").focus();
          break;
        case LessonOperationEnum.ExtraEnrichmentExercise:
          window.open(leafData.linkForExtraEnrichmentExercise, "_blank").focus();
          break;
      }
    },
    async unlockLessonOperationItem(
      evt,
      leafData,
      category,
      item,
      linkText,
      isPoints,
      pointsOrDiscountKey
    ) {
      let unlockResult = false;
      let unlockData = this.calculateUnlockData(
        leafData,
        category,
        item,
        isPoints,
        pointsOrDiscountKey
      );
      console.log(
        "unlockLessonItem",
        unlockData,
        evt,
        leafData,
        category,
        item,
        postData
      );
      let discountOff = "";
      if (unlockData.discountPoints > 0) {
        discountOff = ` (${Math.round(100 * (1 - unlockData.discountRate))}% off)`;
      }

      let confirmRet = await this.$confirm(
        `<b style='color:red;font-size:18px;'>${unlockData.points
        } points${discountOff}</b> to unlock ${unlockData.durationDays
        } days till to ${this.$formatter.formatDate(unlockData.expiryTime)}.`,
        linkText,
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
          dangerouslyUseHTMLString: true,
        }
      );
      console.info("unlockLessonOperationItem", confirmRet);
      if (confirmRet === "confirm") {
        let res = await postData(`/student/purchaseItemViaPoints`, {
          objectType: "Lesson",
          objectId: leafData.lessonId,
          itemId: item,
          name: leafData.name,
          description: linkText,
          points: unlockData.points,
        });

        if (res.result && res.code === "200") {
          this.$user.refreshPoints();
          // enable operation button
          let divElm = evt.target.parentNode;
          let aElm = divElm.getElementsByTagName("a");
          if (aElm && aElm.length === 2) {
            aElm[0].disabled = false;
            aElm[0].innerHTML = linkText;
            aElm[0].className = `active ${item.toLocaleLowerCase()}`;

            divElm.removeChild(aElm[1]);
          }
          unlockResult = true;
          this.purchasedRecords.push(res.result);
          this.$message.info("Purchase successfully");
        } else if (res.code === "414") {
          this.$alert(
            "Points are not enough.<br>Please top up. <br>10 points = $1.",
            "Prompt",
            {
              dangerouslyUseHTMLString: true,
            }
          );
        } else {
          this.$message.error(
            "Fetch purchased records failed, error message: " + res.message
          );
        }
      }

      return unlockResult;
    },
    calculateUnlockData(leafData, category, item, isPoints, pointsOrDiscountKey) {
      let points = 0;
      let discountedPoints = 0;
      let generalDiscount = 1;
      let pointsCalculateDiscount = this.$appSetting.getGenericValue(
        pointsOrDiscountKey,
        0.1
      );
      pointsCalculateDiscount = parseFloat(pointsCalculateDiscount);
      let ratioForCashToPoints = this.$appSetting.getGenericValue(
        // 10 points for $1
        "CASH_TO_POINTS_RATIO",
        10
      );
      ratioForCashToPoints = parseFloat(ratioForCashToPoints);
      if (isPoints === true) {
        if (category === LessonCategoryEnum.ExtraExercise) {
          let name = `pagesFor${item}`;
          points = pointsCalculateDiscount * leafData[name];
          discountedPoints = points;
          generalDiscount = 1;
        } else {
          points = pointsCalculateDiscount;
          discountedPoints = points;
          generalDiscount = 1;
        }
      } else {
        let course = this.courseList.find(
          (e) => e.courseId === leafData.courseId || e.grade === leafData.grade
        );
        let step = this.getCalculatingStep(leafData);

        if ((this.studentInClassRooms || []).length > 0) {
          generalDiscount = parseFloat(
            this.$appSetting.getGenericValue("POINTS_CALCULATE_ACTIVE_PROMOTION", 1)
          );
        } else {
          generalDiscount = parseFloat(
            this.$appSetting.getGenericValue("POINTS_CALCULATE_INACTIVE_PROMOTION", 1)
          );
        }
        points =
          course.unitPrice *
          course.lessonHours *
          pointsCalculateDiscount *
          ratioForCashToPoints *
          (1 + step);
        discountedPoints = points * generalDiscount;

        points = Math.ceil(points);
        discountedPoints = Math.ceil(discountedPoints);

        console.log(
          "calculateUnlockData",
          pointsOrDiscountKey,
          pointsCalculateDiscount,
          step,
          points,
          discountedPoints
        );
      }

      let durationDays = parseFloat(
        this.$appSetting.getGenericValue("LESSON_UNLOCK_DURATION_DAYS", 180)
      );
      let expiryTime = new Date(Date.now() + durationDays * 24 * 60 * 60 * 1000);

      return {
        points: discountedPoints,
        discountPoints: points - discountedPoints,
        discountRate: generalDiscount,
        durationDays: durationDays,
        expiryTime: expiryTime,
      };
    },
    getCalculatingStep(leafData) {
      let maxGrade = 0;
      let maxTerm = 0;
      if ((this.studentInClassRooms || []).length > 0) {
        this.studentInClassRooms.forEach((e) => {
          maxGrade = Math.max(e.classRoom.gradeValue, maxGrade);
          maxTerm = Math.max(e.classRoom.termValue, maxTerm);
        });
      } else {
        maxGrade = 6;
        maxTerm = 3;
      }
      let step = parseFloat(
        this.$appSetting.getGenericValue("POINTS_CALCULATE_STEP_FOR_GRADE", 0.2)
      );
      let termStep = step / 4;
      let diffOfGrade = leafData.gradeValue - maxGrade;
      let diffOfTerm = leafData.termValue - maxTerm;
      let stepsCount = diffOfGrade * 4 + diffOfTerm;
      console.info(
        "getCalculatingStep",
        maxGrade,
        maxTerm,
        leafData.gradeValue,
        leafData.termValue,
        stepsCount,
        stepsCount * termStep
      );
      return stepsCount * termStep;
    },
    async loadStudentUnreadCountList() {
      let res = await getData(`/forum/getUnreadCountList`);
      if (res.result && res.code === "200") {
        this.unreadMessageCountList = res.result;
      }
    },
    getStudentUnreadForumMessageCount(forumId) {
      if (this.unreadMessageCountList && this.unreadMessageCountList.length) {
        let item = this.unreadMessageCountList.find(e => e.forumId === forumId);
        return item ? item.unreadCount : 0;
      }
      return 0;
    },
    clearStudentUnreadForumMessageCount(forumId) {
      if (this.unreadMessageCountList && this.unreadMessageCountList.length) {
        let item = this.unreadMessageCountList.find(e => e.forumId === forumId);
        if (item) {
          console.log("clearStudentUnreadForumMessageCount", forumId, item);
          let popDotElm = document.getElementById(`unread_forum_message_count_${forumId}`)
          if (popDotElm) popDotElm.style.display = "none";
          item.unreadCount = 0;
        }
      }
    },
  },
};
</script>

<style scoped>
.canvasWrapper {
  position: relative;
}

.canvasWrapper .handle-box {
  position: absolute;
  left: 10px;
  top: 10px;
  z-index: 100;
}

.canvasWrapper .gradeAndTerm {
  width: 120px;
  margin-right: 5px;
}

.canvasWrapper .zoomInOutPanel {
  position: absolute;
  left: 10px;
  top: 60px;
  z-index: 100;
}

.canvasWrapper .canvasPanel {
  position: absolute;
  width: 100%;
  overflow-x: auto;
  overflow-y: scroll;
  z-index: 1;
}

.canvasWrapper .canvasPanel canvas {
  display: block;
}

.video_dialog_container {
  position: relative;
}

.video_dialog_container .video_dialog_logo {
  right: 10px;
  top: 10px;
  z-index: 100;
  position: absolute;
}

.video_dialog_container .video_dialog_content {
  left: 0;
  top: 0;
  z-index: 1;
  position: absolute;
}

.branchVSColor {
  position: absolute;
  left: 10px;
  top: 140px;
  z-index: 100;
}

.branchVSColor .item {
  margin-top: 10px;
  text-align: center;
  line-height: 1.67rem;
  height: 1.67rem;
  width: 6.67rem;
  display: inline-block;
}

.leafVSColor {
  position: absolute;
  left: 10px;
  top: 320px;
  z-index: 100;
}

.leafVSColor .item {
  margin-top: 10px;
  text-align: center;
  line-height: 1.67rem;
  height: 1.67rem;
  width: 8.34rem;
  display: inline-block;
}
</style>

<style>
.el-avatar>img {
  margin: auto !important;
  display: block !important;
}

.lessonOperationalDialog * {
  font-size: 1.34rem;
}

.lessonOperationalDialog .operation a.active,
.lessonOperationalDialog .operation a.disabled {
  padding: 5px 0;
  cursor: pointer;
  color: blue;
}

.lessonOperationalDialog .operation a.disabled {
  cursor: pointer;
  color: #ccc;
  pointer-events: none;
}

.lessonOperationalDialog .operation a.unlock {
  position: absolute;
  right: 5px;
  padding: 0;
}

.lessonOperationalDialog .operation a:hover {
  color: orange;
}

.lessonOperationalDialog .el-message-box__title span {
  font-weight: bold !important;
}
</style>
